/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* make collapsable menus look the same as non-collapsable */
fuse-vertical-navigation{

    fuse-vertical-navigation-collapsable-item {
        > .fuse-vertical-navigation-item-wrapper {
            text-transform: uppercase;
            color: rgba(var(--fuse-primary-400-rgb), var(--tw-text-opacity));
            --tw-text-opacity: 1;
        }
    
    }
    
    fuse-vertical-navigation-collapsable-item {
        .fuse-vertical-navigation-item-children {
            .fuse-vertical-navigation-item{
                padding-left: 15px !important;
            }
        } 
    } 

    .fuse-vertical-navigation-content {
        > fuse-vertical-navigation-basic-item {
            > .fuse-vertical-navigation-item-wrapper {
                text-transform: uppercase;
                color: rgba(var(--fuse-primary-400-rgb), var(--tw-text-opacity));
                --tw-text-opacity: 1;
            }
        }
    }

}